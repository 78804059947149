<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("Inscrits") }}</h1>
      <br>
      <vx-card  v-if="user_role!=='agent'" ref="filterCard" title="Filtre" class="user-list-filters mb-4"  >
        <div class="vx-row">
          <div  v-if="user_role!=='agent'" class="vx-col w-1/2">
            <p class="mb-2" > Régions </p>
            <v-select v-model="region_filter" :options="regions" :reduce="rep => rep.id" label="name" autocomplete >
            </v-select>
          </div>
          <div  v-if="user_role!=='agent'" class="vx-col w-1/2">
            <p class="mb-2" > Numéro de téléphone </p>
            <v-select v-model="phoneNumber_filter" :options="agents" :reduce="rep => rep.phoneNumber" label="phoneNumber" autocomplete >
            </v-select>
          </div>

        </div>
      </vx-card>
      <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />
  
      <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="ownerOfPdvs">
  
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
  
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <div
             class="btn-add-new p-3 mb-5 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
                <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                <span class="ml-2 text-base text-primary">{{ $t("Ajouter") }}</span>
            </div>
            <download-excel
            v-if="user_role!=='agent'"
            class="btn-add-new icon-user p-2 mb-4 mr-2 ml-2 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
            :fetch= "fetchData"
            :fields="json_fields"
            worksheet="My Worksheet"
            name="PDV.xls"
          >
            Exporter
          </download-excel>
          </div>
  
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ ownerOfPdvs.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : ownerOfPdvs.length }} of {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
  
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=40">
                <span>40</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=60">
                <span>60</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=80">
                <span>80</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
  
        <template slot="thead">
          <vs-th sort-key="id">N°PDV</vs-th>
          <vs-th sort-key="Nom_Prenons">{{ $t("Nom_Prenons") }}</vs-th>
          <vs-th sort-key="date_naiss">Date de naissance</vs-th>  
          <vs-th sort-key="Genre">Genre</vs-th>          
          <vs-th sort-key="situation">Situation matrimoniale</vs-th>
          <vs-th sort-key="Region">Région</vs-th>
          <vs-th sort-key="localité">Localité</vs-th>
          <vs-th sort-key="Adresse">Adresse</vs-th>
          <vs-th sort-key="Téléphone ">Téléphone </vs-th>
          <vs-th sort-key="Email">Email</vs-th>
          <vs-th sort-key="Entreprise">Entreprise</vs-th>
          <vs-th v-if="user_role!=='agent'"  sort-key="id">télephone agent</vs-th>
          <vs-th>{{ $t("Action") }}</vs-th>
          </template>
  
          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
  
                <vs-td>
                <p class="product-name font-medium truncate">{{ tr.id}}</p>
                </vs-td>
              
                <vs-td>
                <p class="product-name font-medium truncate">{{ tr.fullName}}</p>
                </vs-td>
  
                <vs-td >
                  <p class="product-name font-medium truncate">{{ tr.birthdate }}</p>
                </vs-td>
  
                <vs-td >
                  <p class="product-name font-medium truncate" >{{  GENDERCHOICES[tr.gender] }}</p>
                </vs-td>
                
                <vs-td>
                  <p class="product-name font-medium truncate">{{ MARITALSTATUSCHOICES[tr.maritalStatus]}}</p>
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.region !== null ? tr.region.name : '-'}}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.locality}}</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.address}}</p>
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.phoneNumber}}</p>
                </vs-td>
                
                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.email}}</p>
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">{{ tr.corporate}}</p>
                </vs-td>
                <vs-td v-if="user_role!=='agent'" >
                <p class="product-name font-medium truncate">{{ tr.author.phoneNumber}}</p>
                </vs-td>
                <vs-td  class="whitespace-no-wrap">
                  <div class="flex">
                    <vx-tooltip text="Voir ma photo" color="primary">
                      <feather-icon
                        style="color: #438eff"
                        icon="CameraIcon"
                        svgClasses="w-5 h-5 hover:text-primary stroke-current"
                        class="mr-2"
                        @click="Voirpicture(tr)"
                      />
                    </vx-tooltip>
                    <vx-tooltip text="Voir les bénéficiares" color="success">
                      <feather-icon
                        style="color: #69ff43"
                        icon="EyeIcon"
                        svgClasses="w-5 h-5 hover:text-success stroke-current"
                        class="mr-2"
                        @click="VoirData(tr)"
                      />
                    </vx-tooltip>
                     <vx-tooltip :text="$t('Modifier')" color="warning">
                      <feather-icon
                        style="color: #FF9F43"
                        icon="EditIcon"
                        svgClasses="w-5 h-5 hover:text-warning stroke-current"
                        class="mr-2"
                        @click="editData(tr)"
                      />
                    </vx-tooltip>
                    <vx-tooltip v-if="user_role!=='agent'" :text="$t('Supprimer')" color="danger">
                      <feather-icon
                        @click="deleteData(tr.id)"
                        style="color: red"
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        class="mr-2"
                      />
                    </vx-tooltip>
  
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
      </vs-table>

    <vs-popup
    v-if="activePrompt2" 
      fullscreen 
      class="calendar-event-dialog"
      title="Ajouter un PDV"
      :active.sync="activePrompt2">
      <div class="clearfix">
      <div class="vx-row">
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
          <!-- NOM ET PRENOMS-->
          <p>Nom & Prénoms <b style="color: #ff6141">*</b></p>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="fullName"
            v-model="fullName"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('fullName')">{{ errors.first("fullName") }}</span>
  
            <!-- Date de naissance-->
          <p>Date de naissance <b style="color: #ff6141">*</b></p>
          <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="birthdate"
            v-validate="'required'"
            name="birthdate"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('birthdate')">{{ errors.first("birthdate") }}</span>
  
        <p> Genre <b style="color: #ff6141" >*</b> </p>
            <v-select
              v-validate="'required'"
              name="gender"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="gender"
              :options="genders">
            </v-select>
            <span class="text-danger text-sm" v-show="errors.has('gender')">{{ errors.first("gender") }}</span>
  
   
          <p> Situation matrimoniale <b style="color: #ff6141" >*</b> </p>
            <v-select
              v-validate="'required'"
              name="maritalStatus"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="maritalStatus"
              :options="situations">
            </v-select>
            <span class="text-danger text-sm" v-show="errors.has('maritalStatus')">{{ errors.first("maritalStatus") }}</span>
  
  
            <p>Adresse <b style="color: #ff6141">*</b></p>
            <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="address"
            v-model="address"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('address')">{{ errors.first("address") }}</span>
  
            
            <!-- Telephone-->
            <p>Téléphone <b style="color: #ff6141">*</b></p>
            <vue-tel-input
              v-on:country-changed="countryChanged"
              inputOptions.placeholder="Telephone"
              :mode="national"
              :autoFormat="false"
              v-model="phoneNumber"
              data-vv-validate-on="blur"
              name="phoneNumber"
              v-validate="'required|min:8|max:8'"
            ></vue-tel-input>
            <span class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{errors.first("phoneNumber")}}</span>
          
            <!--EMAIL-->
            <p>Email</p>
            <vs-input
              data-vv-validate-on="blur"
              name="email"
              type="email"
              v-model="email"
              class="w-full"
            />
            <p> Photo adhérent<b style="color: #ff6141">*</b>  </p>
            <div class="upload-img mt-2">
              <input  v-validate="'required'" name="name_file6" type="file" class="hidden" ref="uploadImgInput6" @change="updateCurrImg6"  accept="image/*">
              <span><b style="color:orange">{{ name_file6 }}</b></span>
              <vs-button  @click="$refs.uploadImgInput6.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
              <span class="text-danger text-sm" v-show="errors.has('name_file6')">{{ errors.first("name_file6") }}</span>

            </div>
  
            <p>Entreprise <b style="color: #ff6141">*</b></p>
            <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="corporate"
            v-model="corporate"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('corporate')">{{ errors.first("corporate") }}</span>
            
            <p>Région<b style="color: #ff6141">*</b></p>
              <vs-select
                  v-model="region"
                  v-validate="'required'"
                  autocomplete
                  clearable
                  class="w-full "
                  name="region"
              >
              <vs-select-item :key="item" :value="item.id" :text="item.name" v-for="item in regions" /></vs-select>
              <span class="text-danger text-sm" v-show="errors.has('region')">{{errors.first("region")}}</span>
        
              <p>Localité de point de vente <b style="color: #ff6141">*</b></p>
              <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="locality"
              v-model="locality"
              class="w-full"
              />
              <span class="text-danger text-sm" v-show="errors.has('locality')">{{ errors.first("locality") }}</span>
  
          </div>
  
          <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
  
          
            <p> Bénéficiaire 1  </p>
            <v-select
              name="beneficier1"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="recipient1"
              :options="beneficiers">
            </v-select>
            <p   v-if="recipient1" >Nom & Prénoms <b style="color: #ff6141">*</b></p>
            <vs-input
            v-if="recipient1" 
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="fullName1"
              v-model="fullName1"
              class="w-full"
            />
            <span v-if="recipient1"  class="text-danger text-sm" v-show="errors.has('fullName1')">{{ errors.first("fullName") }}</span>
  
            <p  v-if="recipient1">Date de naissance <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            v-if="recipient1" 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="birthdate1"
            v-validate="'required'"
            name="birthdate"
            class="w-full" />
            <span v-if="recipient1"  class="text-danger text-sm" v-show="errors.has('birthdate')">{{ errors.first("birthdate") }}</span>
            
            <div v-if="recipient1" class="upload-img mt-2">
              <span>Photo:</span> 
              <span><b style="color:orange">{{ name_file1_1 }}</b></span>
              <input   v-validate="'required'" name="name_file1_1" type="file" class="hidden" ref="uploadImgInput1_1" @change="updateCurrImg1_1" accept="image/*">
              <span class="text-danger text-sm" v-show="errors.has('name_file1_1')">{{ errors.first("name_file1_1") }}</span>
              <vs-button  @click="$refs.uploadImgInput1_1.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
            
              <span>CNI ou Extrait:</span> 
              <span><b style="color:orange">{{ name_file1_2 }}</b></span>
              <input v-validate="'required'" name="name_file1_2" type="file" class="hidden" ref="uploadImgInput1_2" @change="updateCurrImg1_2" accept="image/*">
              <span class="text-danger text-sm" v-show="errors.has('name_file1_2')">{{ errors.first("name_file1_2") }}</span>
              <vs-button  @click="$refs.uploadImgInput1_2.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
              
              <div v-if="date1 & recipient1 ==='child'">
              <span>Certificat de scolarité:</span> 
              <span><b style="color:orange">{{ name_file1_3 }}</b></span>
              <input v-validate="'required'" name="name_file1_3" type="file"  class="hidden" ref="uploadImgInput1_3" @change="updateCurrImg1_3" accept=".pdf,image/*">
              <span class="text-danger text-sm" v-show="errors.has('name_file1_3')">{{ errors.first("name_file1_3") }}</span>
              <vs-button @click="$refs.uploadImgInput1_3.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
              
              </div>
            
            </div>

          
            <p> Bénéficiaire 2 </p>
            <v-select
              name="beneficier2"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="recipient2"
              :options="beneficiers">
            </v-select>
  
            <p  v-if="recipient2" >Nom & Prénoms <b style="color: #ff6141">*</b></p>
            <vs-input
              v-if="recipient2" 
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="fullName2"
              v-model="fullName2"
              class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('fullName2')">{{ errors.first("fullName") }}</span>
  
            <p  v-if="recipient2">Date de naissance <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            v-if="recipient2" 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="birthdate2"
            v-validate="'required'"
            name="birthdate2"
            class="w-full" />
            <span v-if="recipient2"  class="text-danger text-sm" v-show="errors.has('birthdate2')">{{ errors.first("birthdate") }}</span>
            
            <div v-if="recipient2" class="upload-img mt-2">
              <span>Photo:</span> 
              <span><b style="color:orange">{{ name_file2_1 }}</b></span>
              <input v-validate="'required'" name="name_file2_1" type="file" class="hidden" ref="uploadImgInput2_1" @change="updateCurrImg2_1" accept="image/*">
              <span class="text-danger text-sm" v-show="errors.has('name_file2_1')">{{ errors.first("name_file2_1") }}</span>
              <vs-button @click="$refs.uploadImgInput2_1.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
           
              <span>CNI ou Extrait:</span> 
              <span><b style="color:orange">{{ name_file2_2}}</b></span>
              <input v-validate="'required'" name="name_file2_2" type="file" class="hidden" ref="uploadImgInput2_2" @change="updateCurrImg2_2" accept="image/*">
              <span class="text-danger text-sm" v-show="errors.has('name_file2_2')">{{ errors.first("name_file2_2") }}</span>
              <vs-button @click="$refs.uploadImgInput2_2.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
              
              <div v-if="date2 && recipient2 ==='child'">
              <span>Certificat de scolarité:</span> 
              <span><b style="color:orange">{{ name_file2_3 }}</b></span>
              <input v-validate="'required'" name="name_file2_3"  type="file" class="hidden" ref="uploadImgInput2_3" @change="updateCurrImg2_3" accept=".pdf,image/*">
              <span class="text-danger text-sm" v-show="errors.has('name_file2_3')">{{ errors.first("name_file2_3") }}</span>
              <vs-button @click="$refs.uploadImgInput2_3.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
              </div>

            </div>
            
           
          </div>
  
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
          <div>

            <p> Bénéficiaire 3 </p>
            <v-select
              name="beneficier3"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="recipient3"
              :options="beneficiers">
            </v-select>
  
            <p v-if="recipient3" >Nom & Prénoms <b style="color: #ff6141">*</b></p>
            <vs-input
              v-if="recipient3"
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="fullName3"
              v-model="fullName3"
              class="w-full"
            />
            <span v-if="recipient3"  class="text-danger text-sm" v-show="errors.has('fullName3')">{{ errors.first("fullName") }}</span>
  
            <p v-if="recipient3">Date de naissance <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            v-if="recipient3" 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="birthdate3"
            v-validate="'required'"
            name="birthdate3"
            class="w-full" />
            <span v-if="recipient1"  class="text-danger text-sm" v-show="errors.has('birthdate3')">{{ errors.first("birthdate") }}</span>
            
            <div v-if="recipient3" class="upload-img mt-2">
              <span>Photo:</span> 
              <span><b style="color:orange">{{ name_file3_1 }}</b></span>
              <input v-validate="'required'" name="name_file3_1" type="file" class="hidden" ref="uploadImgInput3_1" @change="updateCurrImg3_1" accept="image/*">
              <span class="text-danger text-sm" v-show="errors.has('name_file3_1')">{{ errors.first("name_file3_1") }}</span>
              <vs-button @click="$refs.uploadImgInput3_1.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
            
              <span>CNI ou Extrait:</span> 
              <span><b style="color:orange">{{ name_file3_2 }}</b></span>
              <input v-validate="'required'"  name="name_file3_2" type="file" class="hidden" ref="uploadImgInput3_2" @change="updateCurrImg3_2" accept="image/*">
              <span class="text-danger text-sm" v-show="errors.has('name_file3_2')">{{ errors.first("name_file3_2") }}</span>
              <vs-button @click="$refs.uploadImgInput3_2.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
             
              <div v-if="date3 && recipient3 ==='child'">
             <span>Certificat de scolarité:</span> 
              <span><b style="color:orange">{{ name_file3_3 }}</b></span>
              <input v-validate="'required'"  name="name_file3_3" type="file" class="hidden" ref="uploadImgInput3_3" @change="updateCurrImg3_3" accept=".pdf,image/*">
              <span class="text-danger text-sm" v-show="errors.has('name_file3_3')">{{ errors.first("name_file3_3") }}</span>
              <vs-button @click="$refs.uploadImgInput3_3.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
            </div>
            </div>
          
            <p> Bénéficiaire 4  </p>
            <v-select
              name="beneficier4"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="recipient4"
              :options="beneficiers">
            </v-select>
            <p  v-if="recipient4" >Nom & Prénoms <b style="color: #ff6141">*</b></p>
            <vs-input
            v-if="recipient4" 
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="fullName4"
              v-model="fullName4"
              class="w-full"
            />
            <span v-if="recipient4"  class="text-danger text-sm" v-show="errors.has('fullName4')">{{ errors.first("fullName") }}</span>
  
            <p  v-if="recipient4" >Date de naissance <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            v-if="recipient4" 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="birthdate4"
            v-validate="'required'"
            name="birthdate4"
            class="w-full" />
            <span v-if="recipient4"  class="text-danger text-sm" v-show="errors.has('birthdate4')">{{ errors.first("birthdate") }}</span>
            
            <div v-if="recipient4" class="upload-img mt-2">
              <span>Photo:</span> 
              <span><b style="color:orange">{{ name_file4_1 }}</b></span>
              <input v-validate="'required'" name="name_file4_1" type="file" class="hidden" ref="uploadImgInput4_1" @change="updateCurrImg4_1" accept="image/*">
              <span class="text-danger text-sm" v-show="errors.has('name_file4_1')">{{ errors.first("name_file4_1") }}</span>
              <vs-button @click="$refs.uploadImgInput4_1.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
              
              <span>CNI ou Extrait:</span> 
              <span><b style="color:orange">{{ name_file4_2 }}</b></span>
              <input v-validate="'required'"  name="name_file4_2" type="file" class="hidden" ref="uploadImgInput4_2" @change="updateCurrImg4_2" accept="image/*">
              <span class="text-danger text-sm" v-show="errors.has('name_file4_2')">{{ errors.first("name_file4_2") }}</span>
              <vs-button @click="$refs.uploadImgInput4_2.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
              
              <div v-if="date4 && recipient4 ==='child'">
              <span>Certificat de scolarité:</span> 
              <span><b style="color:orange">{{ name_file4_3 }}</b></span>
              <input v-validate="'required'"  name="name_file4_3" type="file" class="hidden" ref="uploadImgInput4_3" @change="updateCurrImg4_3" accept=".pdf,image/*">
              <span class="text-danger text-sm" v-show="errors.has('name_file4_3')">{{ errors.first("name_file4_3") }}</span>
              <vs-button @click="$refs.uploadImgInput4_3.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
              </div>
              <p class="pt-2">
              <span class="font-semibold">NB: Cas particulier pour les bénéciaires agé(és) de plus de 21 ans</span>
              <br>
              <span>• Photo</span>
              <br>
              <span>• Pièce d'identité ou extrait</span>
              <br>
              <span>• Certificat de scolarité (année en cours)</span>
            </p>
            </div>

            
          </div>
        </div>
      </div>
      <vs-button color="success"  class="float-right mt-4"  @click="valider" >Envoyer</vs-button>
     </div>
    </vs-popup>
    
    <vs-popup
    v-if="activePrompt1" 
      fullscreen 
      class="calendar-event-dialog"
      title="Modifier un PDV"
      :active.sync="activePrompt1">
      <div class="clearfix">
        <div class="vx-row">
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
          <!-- NOM ET PRENOMS-->
          <p>Nom & Prénoms <b style="color: #ff6141">*</b></p>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="fullName"
            v-model="fullName"
            class="w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('fullName')">{{ errors.first("fullName") }}</span>
  
            <!-- Date de naissance-->
          <p>Date de naissance <b style="color: #ff6141">*</b></p>
          <flat-pickr 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="birthdate"
            v-validate="'required'"
            name="birthdate"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('birthdate')">{{ errors.first("birthdate") }}</span>
  
        <p> Genre <b style="color: #ff6141" >*</b> </p>
            <v-select
              v-validate="'required'"
              name="gender"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="gender"
              :options="genders">
            </v-select>
            <span class="text-danger text-sm" v-show="errors.has('gender')">{{ errors.first("gender") }}</span>
  
   
          <p> Situation matrimoniale <b style="color: #ff6141" >*</b> </p>
            <v-select
              v-validate="'required'"
              name="maritalStatus"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="maritalStatus"
              :options="situations">
            </v-select>
            <span class="text-danger text-sm" v-show="errors.has('maritalStatus')">{{ errors.first("maritalStatus") }}</span>
  
  
            <p>Adresse <b style="color: #ff6141">*</b></p>
            <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="address"
            v-model="address"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('address')">{{ errors.first("address") }}</span>
  
            
            <!-- Telephone-->
            <p>Téléphone <b style="color: #ff6141">*</b></p>
            <vue-tel-input
              v-on:country-changed="countryChanged"
              inputOptions.placeholder="Telephone"
              :mode="national"
              :autoFormat="false"
              v-model="phoneNumber"
              data-vv-validate-on="blur"
              name="phoneNumber"
              v-validate="'required|min:8|max:8'"
            ></vue-tel-input>
            <span class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{errors.first("phoneNumber")}}</span>
          
            <!--EMAIL-->
            <p>Email</p>
            <vs-input
              data-vv-validate-on="blur"
              name="email"
              type="email"
              v-model="email"
              class="w-full"
            />
            <p> Photo adhérent<b style="color: #ff6141">*</b>  </p>
            <div class="upload-img mt-2">
              <input  type="file" class="hidden" ref="uploadImgInput6" @change="updateCurrImg6"  accept="image/*">
              <span><b style="color:orange">{{ name_file6 }}</b></span>
              <vs-button  @click="$refs.uploadImgInput6.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>

            </div>
  
            <p>Entreprise <b style="color: #ff6141">*</b></p>
            <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="corporate"
            v-model="corporate"
            class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('corporate')">{{ errors.first("corporate") }}</span>
            
            <p>Région<b style="color: #ff6141">*</b></p>
              <vs-select
                  v-model="region"
                  v-validate="'required'"
                  autocomplete
                  clearable
                  class="w-full "
                  name="region"
              >
              <vs-select-item :key="item" :value="item.id" :text="item.name" v-for="item in regions" /></vs-select>
              <span class="text-danger text-sm" v-show="errors.has('region')">{{errors.first("region")}}</span>
        
              <p>Localité de point de vente <b style="color: #ff6141">*</b></p>
              <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="locality"
              v-model="locality"
              class="w-full"
              />
              <span class="text-danger text-sm" v-show="errors.has('locality')">{{ errors.first("locality") }}</span>
  
          </div>
  
          <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
  
          
            <p> Bénéficiaire 1  </p>
            <v-select
              name="beneficier1"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="recipient1"
              :options="beneficiers">
            </v-select>
            <p   v-if="recipient1" >Nom & Prénoms <b style="color: #ff6141">*</b></p>
            <vs-input
              v-if="recipient1" 
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="fullName1"
              v-model="fullName1"
              class="w-full"
            />
            <span v-if="recipient1"  class="text-danger text-sm" v-show="errors.has('fullName1')">{{ errors.first("fullName1") }}</span>
  
            <p  v-if="recipient1">Date de naissance <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            v-if="recipient1" 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="birthdate1"
            v-validate="'required'"
            name="birthdate"
            class="w-full" />
            <span v-if="recipient1"  class="text-danger text-sm" v-show="errors.has('birthdate')">{{ errors.first("birthdate1") }}</span>
            
            <div v-if="recipient1" class="upload-img mt-2">
              <span>Photo:</span> 
              <span><b style="color:orange">{{ name_file1_1 }}</b></span>
              <input    type="file" class="hidden" ref="uploadImgInput1_1" @change="updateCurrImg1_1" accept="image/*">
              <vs-button  @click="$refs.uploadImgInput1_1.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
            
              <span>CNI ou Extrait:</span> 
              <span><b style="color:orange">{{ name_file1_2 }}</b></span>
              <input  type="file" class="hidden" ref="uploadImgInput1_2" @change="updateCurrImg1_2" accept="image/*">
              <vs-button  @click="$refs.uploadImgInput1_2.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
              
              <div v-if="date1 & recipient1 ==='child'">
              <span>Certificat de scolarité:</span> 
              <span><b style="color:orange">{{ name_file1_3 }}</b></span>
              <input  type="file"  class="hidden" ref="uploadImgInput1_3" @change="updateCurrImg1_3" accept=".pdf,image/*">
              <vs-button @click="$refs.uploadImgInput1_3.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
              </div>
            
            </div>

          
            <p> Bénéficiaire 2 </p>
            <v-select
              name="beneficier2"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="recipient2"
              :options="beneficiers">
            </v-select>
  
            <p  v-if="recipient2" >Nom & Prénoms <b style="color: #ff6141">*</b></p>
            <vs-input
              v-if="recipient2" 
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="fullName2"
              v-model="fullName2"
              class="w-full"
            />
            <span class="text-danger text-sm" v-show="errors.has('fullName2')">{{ errors.first("fullName2") }}</span>
  
            <p  v-if="recipient2">Date de naissance <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            v-if="recipient2" 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="birthdate2"
            v-validate="'required'"
            name="birthdate2"
            class="w-full" />
            <span v-if="recipient2"  class="text-danger text-sm" v-show="errors.has('birthdate2')">{{ errors.first("birthdate2") }}</span>
            
            <div v-if="recipient2" class="upload-img mt-2">
              <span>Photo:</span> 
              <span><b style="color:orange">{{ name_file2_1 }}</b></span>
              <input type="file" class="hidden" ref="uploadImgInput2_1" @change="updateCurrImg2_1" accept="image/*">
              <vs-button @click="$refs.uploadImgInput2_1.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
           
              <span>CNI ou Extrait:</span> 
              <span><b style="color:orange">{{ name_file2_2}}</b></span>
              <input type="file" class="hidden" ref="uploadImgInput2_2" @change="updateCurrImg2_2" accept="image/*">
              <vs-button @click="$refs.uploadImgInput2_2.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
              
              <div v-if="date2 && recipient2 ==='child'">
              <span>Certificat de scolarité:</span> 
              <span><b style="color:orange">{{ name_file2_3 }}</b></span>
              <input  type="file" class="hidden" ref="uploadImgInput2_3" @change="updateCurrImg2_3" accept=".pdf,image/*">
              <vs-button @click="$refs.uploadImgInput2_3.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
              </div>

            </div>
            
           
          </div>
  
        <div class="vx-col sm:w-full md:w-1/3 lg:1/3 xs:w-full">
          <div>

            <p> Bénéficiaire 3 </p>
            <v-select
              name="beneficier3"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="recipient3"
              :options="beneficiers">
            </v-select>
  
            <p v-if="recipient3" >Nom & Prénoms <b style="color: #ff6141">*</b></p>
            <vs-input
              v-if="recipient3"
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="fullName3"
              v-model="fullName3"
              class="w-full"
            />
            <span v-if="recipient3"  class="text-danger text-sm" v-show="errors.has('fullName3')">{{ errors.first("fullName3") }}</span>
  
            <p v-if="recipient3">Date de naissance <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            v-if="recipient3" 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="birthdate3"
            v-validate="'required'"
            name="birthdate3"
            class="w-full" />
            <span v-if="recipient1"  class="text-danger text-sm" v-show="errors.has('birthdate3')">{{ errors.first("birthdate3") }}</span>
            
            <div v-if="recipient3" class="upload-img mt-2">
              <span>Photo:</span> 
              <span><b style="color:orange">{{ name_file3_1 }}</b></span>
              <input type="file" class="hidden" ref="uploadImgInput3_1" @change="updateCurrImg3_1" accept="image/*">
              <vs-button @click="$refs.uploadImgInput3_1.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
            
              <span>CNI ou Extrait:</span> 
              <span><b style="color:orange">{{ name_file3_2 }}</b></span>
              <input type="file" class="hidden" ref="uploadImgInput3_2" @change="updateCurrImg3_2" accept="image/*">
              <vs-button @click="$refs.uploadImgInput3_2.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
             
              <div v-if="date3 && recipient3 ==='child'">
             <span>Certificat de scolarité:</span> 
              <span><b style="color:orange">{{ name_file3_3 }}</b></span>
              <input  type="file" class="hidden" ref="uploadImgInput3_3" @change="updateCurrImg3_3" accept=".pdf,image/*">
              <vs-button @click="$refs.uploadImgInput3_3.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
            </div>
            </div>
          
            <p> Bénéficiaire 4  </p>
            <v-select
              name="beneficier4"
              :reduce="rep => rep.key"
              class="w-full" label="name"
              v-model="recipient4"
              :options="beneficiers">
            </v-select>
            <p  v-if="recipient4" >Nom & Prénoms <b style="color: #ff6141">*</b></p>
            <vs-input
            v-if="recipient4" 
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="fullName4"
              v-model="fullName4"
              class="w-full"
            />
            <span v-if="recipient4"  class="text-danger text-sm" v-show="errors.has('fullName4')">{{ errors.first("fullName4") }}</span>
  
            <p  v-if="recipient4" >Date de naissance <b style="color: #ff6141">*</b></p>
            <flat-pickr 
            v-if="recipient4" 
            :config="configdateTimePicker" 
            label-placeholder=" Date"
            v-model="birthdate4"
            v-validate="'required'"
            name="birthdate4"
            class="w-full" />
            <span v-if="recipient4"  class="text-danger text-sm" v-show="errors.has('birthdate4')">{{ errors.first("birthdate4") }}</span>
            
            <div v-if="recipient4" class="upload-img mt-2">
              <span>Photo:</span> 
              <span><b style="color:orange">{{ name_file4_1 }}</b></span>
              <input  type="file" class="hidden" ref="uploadImgInput4_1" @change="updateCurrImg4_1" accept="image/*">
              <vs-button @click="$refs.uploadImgInput4_1.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
              
              <span>CNI ou Extrait:</span> 
              <span><b style="color:orange">{{ name_file4_2 }}</b></span>
              <input type="file" class="hidden" ref="uploadImgInput4_2" @change="updateCurrImg4_2" accept="image/*">
              <vs-button @click="$refs.uploadImgInput4_2.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
              
              <div v-if="date4 && recipient4 ==='child'">
              <span>Certificat de scolarité:</span> 
              <span><b style="color:orange">{{ name_file4_3 }}</b></span>
              <input type="file" class="hidden" ref="uploadImgInput4_3" @change="updateCurrImg4_3" accept=".pdf,image/*">
              <vs-button @click="$refs.uploadImgInput4_3.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
              </div>
             
            </div>

            <p class="pt-2">
              <span class="font-semibold">NB: Cas particulier pour les bénéciaires agé(és) de plus de 21 ans</span>
              <br>
              <span>• Photo</span>
              <br>
              <span>• Pièce d'identité ou extrait</span>
              <br>
              <span>• Certificat de scolarité (année en cours)</span>
              </p>
          </div>
        </div>
      </div>
      <vs-button color="success"  class="float-right mt-4"  @click="valider" >Envoyer</vs-button>
     </div>
    </vs-popup>

    <vs-popup
    v-if="activePrompt3" 
      fullscreen
      class="calendar-event-dialog"
      title="VOIR LES BÉNÉFICIAIRES"
      :active.sync="activePrompt3">
      <vs-table stripe  search  :data="bene_object">
        <template slot="header">
          <h3>
            Bénéficiaire
          </h3>
        </template>
        <template slot="thead">
          <vs-th>Nom complet</vs-th>
          <vs-th>Date de naissance</vs-th>
          <vs-th>Situation</vs-th>
          <vs-th>Fichiers</vs-th>
          <vs-th>Action</vs-th>

        </template>
        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
            <vs-td>
              <p  class="product-name font-medium truncate">{{ tr.nom}}</p>
            </vs-td>
            <vs-td>
              <p  class="product-name font-medium truncate">{{ tr.birthday}}</p>
            </vs-td>
            <vs-td>
              <p  class="product-name font-medium truncate">{{ RECIPIENTCHOICES[tr.situation]}}</p>
            </vs-td>
           
            <vs-td>
              <span class="mr-1" v-for="fichier in tr.fichiers" :key="fichier" >
              <p  v-if="fichier"  class="product-name font-medium truncate ">{{ fichier}}</p>
              </span>
            </vs-td>
             <vs-td>
              <span class="mr-1" v-for="fichier in tr.fichiers" :key="fichier">
               <vs-button v-if="fichier"  @click.stop="openfiles(fichier)" @click="activePrompt = true" color="warning" size="small" type="filled" icon-pack="feather" icon="icon-eye">Voir</vs-button>
              </span>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>

    </div>
  </template>
  
<script>
// For custom error message
import { Validator } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
const French = require('flatpickr/dist/l10n/fr.js').default.fr
const link = 'https://pdv.moozistudio.com/transvieform/'
const link2 = 'https://pdv.moozistudio.com/transvieform/'

const dict = {
  custom: {
    fullName: {
      required: 'Le champ nom et prénom est obligatoire'
    },
    fullName1: {
      required: 'Le champ nom et prénom est obligatoire'
    }, 
    fullName2: {
      required: 'Le champ nom et prénom est obligatoire'
    }, 
    fullName3: {
      required: 'Le champ nom et prénom est obligatoire'
    },
    fullName4: {
      required: 'Le champ nom et prénom est obligatoire'
    },
    gender: {
      required: 'Le champ genre est obligatoire'
    },
    birthdate: {
      required: 'Le champ date est obligatoire'
    },
    birthdate1: {
      required: 'Le champ date est obligatoire'
    }, 
    birthdate2: {
      required: 'Le champ date est obligatoire'
    }, 
    birthdate3: {
      required: 'Le champ date est obligatoire'
    },
    birthdate4: {
      required: 'Le champ date est obligatoire'
    },
    phoneNumber: {
      required: 'Le champ téléphone est obligatoire',
      min: 'Au minimum 8 caractères.',
      max: 'Au minimum 8 caractères.'
    },
     
    email: {
      required: 'Le champ email est obligatoire'
    },
  
    corporate: {
      required: 'Le champ entreprise est obligatoire'
    },
  
    address: {
      required: 'Le champ adresse est obligatoire'
    },
  
    maritalStatus: {
      required: 'Le champ situation est obligatoire'
    },
  
    locality: {
      required: 'Le champ localité est obligatoire'
    },
    region: {
      required: 'Le champ region est obligatoire'
    },

    name_file6:{
      required : 'La photo est obligatoire '
    },
    name_file1_1:{
      required : 'La photo est obligatoire '
    },
    name_file1_2:{
      required:'CNI ou Extrait'
    },
    name_file1_3:{
      required:'Certificat de scolarité'
    },
    name_file2_1:{
      required : 'La photo est obligatoire '
    },
    name_file2_2:{
      required:'CNI ou Extrait'
    },
    name_file2_3:{
      required:'Certificat de scolarité'
    },
    name_file3_1:{
      required : 'La photo est obligatoire '
    },
    name_file3_2:{
      required:'CNI ou Extrait'
    },
    name_file3_3:{
      required:'Certificat de scolarité'
    },
    name_file4_1:{
      required : 'La photo est obligatoire '
    },
    name_file4_2:{
      required:'CNI ou Extrait'
    },
    name_file4_3:{
      required:'Certificat de scolarité'
    },
    name_file5_1:{
      required : 'La photo est obligatoire '
    },
    name_file5_2:{
      required:'CNI ou Extrait'
    },
    name_file5_3:{
      required:'Certificat de scolarité'
    }
  }
}
  
// register custom messages
Validator.localize('en', dict)
  
export default {
  components: {
    flatPickr
      
  },
  data () {
    return {
      checkId:[],
      updatecreate:false,
      phoneNumber_filter:'',
      region_filter:'',
      json_fields: {
        'N°PDV': 'id',
        'NOM & PRÉNOMS': 'fullName',
        'DATE DE NAISSANCE': 'birthdate',
        'GENRE': 'gender',
        'SITUATION MATRIMONIALE': 'maritalStatus',
        'RÉGION': 'region.name',
        'LOCALITÉ':'locality',
        'ADRESSE':'address',
        'TÉLÉPHONE':'phoneNumber',
        'EMAIL':'email',
        'ENTREPRISE':'corporate',
        'BÉNÉFICIAIRE-1':'fullName1',
        'DATE DE NAISSANCE-1':'birthdate1',
        'SITUATION-1':'recipient1',
        'BÉNÉFICIAIRE-2':'fullName2',
        'DATE DE NAISSANCE-2':'birthdate2',
        'SITUATION-2':'recipient2',
        'BÉNÉFICIAIRE-3':'fullName3',
        'DATE DE NAISSANCE-3':'birthdate1',
        'SITUATION-3':'recipient3',
        'BÉNÉFICIAIRE-4':'fullName4',
        'DATE DE NAISSANCE-4':'birthdate4',
        'SITUATION-4':'recipient4',
        'TÉLÉPHONE AGENT':'author.phoneNumber'
      },
      id:null,
      user_role:'',
      activePrompt3:false,
      activePrompt2:false,
      activePrompt1:false,
      GENDERCHOICES: {
        male: 'Masculin',
        female:'Feminin'
      },
      RECIPIENTCHOICES: {
        child: 'Enfant',
        wifeorhusband:'Epoux(se)'
      },
      MARITALSTATUSCHOICES: {
        single:'Célibataire',
        married: 'Marié',
        divorced:'Divorcé',
        widowed:' Veuf(ve)'
      },
      ownerOfPdvs: [],
      selected: [],
      itemsPerPage: 20,
      isMounted: false,
      addNewDataSidebar: false,
      sidebarData: {},
      configdateTimePicker: {
        enableTime: 'fasle',
        dateFormat: 'Y-m-d',
        locale: French
      },
      beneficiers: [
        {
          key: 'wifeorhusband',
          name: 'Epoux(se)'
        },
        {
          key: 'child',
          name: 'Enfant'
        }
      ],
      genders: [
        {
          key: 'male',
          name: 'Masculin'
        },
        {
          key: 'female',
          name: 'Féminin'
        }
      ],
      situations: [
        {
          key: 'single',
          name: 'Célibataire'
        },
        {
          key: 'married',
          name: 'Marié(e)'
        },        
        {
          key: 'divorced',
          name: 'Divorcé'
        },        
        {
          key: 'widowed',
          name: 'Veuf(ve)'
        }
      ],
      corporates:[],
      fullName:'',
      fullName1:'',
      fullName2:'',
      fullName3:'',
      fullName4:'',
      fullName5:'',
      gender:'',
      phoneNumber:'',
      birthdate:'',
      birthdate1:'',
      birthdate2:'',
      birthdate3:'',
      birthdate4:'',
      birthdate5:'',
      address:'',
      corporate:'',
      maritalStatus:'',
      locality:'',
      memberPicture:'',
      email:'',
      region:'',
      recipient1:null,
      recipient2:null,
      recipient3:null,
      recipient4:null,
      recipient5:null,
      regions:[],
      countryCode: '',
      name_file1_1:'',
      name_file1_2:'',
      name_file1_3:'',
      name_file2_1:'',
      name_file2_2:'',
      name_file2_3:'',
      name_file3_1:'',
      name_file3_2:'',
      name_file3_3:'',
      name_file4_1:'',
      name_file4_2:'',
      name_file4_3:'',
      name_file5_1:'',
      name_file5_2:'',
      name_file5_3:'',
      name_file6:'',
      dataImg1_1:'',
      dataImg1_2:'',
      dataImg1_3:'',

      dataImg2_1:'',
      dataImg2_2:'',
      dataImg2_3:'',

      dataImg3_1:'',
      dataImg3_2:'',
      dataImg3_3:'',

      dataImg4_1:'',
      dataImg4_2:'',
      dataImg4_3:'',

      dataImg5_1:'',
      dataImg5_2:'',
      dataImg5_3:'',
      dataImg6:'',

      date1 : 0,
      date2 : 0,
      date3 : 0,
      date4 : 0,
      date5 : 0,
      agents:[]
     
    }
  },
  watch: {
    phoneNumber_filter (value) {
      if (value) {
        //this.ownerOfPdvs = []
        const phoneNumber = value
        this.region_filter = ''
        this.$vs.loading()
        this.$http.post('owner-filter/', { phoneNumber})
          .then((response) => {
            this.ownerOfPdvs = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      } else {
        this.getAllownerOfPdvs()
      }
    },
    region_filter (value) {
      if (value) {
        //this.ownerOfPdvs = []
        this.phoneNumber_filter = ''
        const region = value
        this.$vs.loading()
        this.$http.post('owner-filter/', { region})
          .then((response) => {
            this.ownerOfPdvs = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      } else {
        this.getAllownerOfPdvs()
      }
    },
    birthdate1 (value) {
      if (value) {
        const birthdate = value
        this.$vs.loading()
        this.$http.post('recipient-age/', { birthdate})
          .then((response) => {
            this.date1 = response.data.age
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })

      } 
    },
   
    birthdate2 (value) {
      if (value) {
        const birthdate = value
        this.$vs.loading()
        this.$http.post('recipient-age/', { birthdate})
          .then((response) => {
            this.date2 = response.data.age
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })

      } 
    },
    birthdate3 (value) {
      if (value) {
        const birthdate = value
        this.$vs.loading()
        this.$http.post('recipient-age/', { birthdate})
          .then((response) => {
            this.date3 = response.data.age
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })

      } 
    },
    birthdate4 (value) {
      if (value) {
        const birthdate = value
        this.$vs.loading()
        this.$http.post('recipient-age/', { birthdate})
          .then((response) => {
            this.date4 = response.data.age
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })

      } 
    },
    birthdate5 (value) {
      if (value) {
        const birthdate = value
        this.$vs.loading()
        this.$http.post('recipient-age/', { birthdate})
          .then((response) => {
            this.date5 = response.data.age
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })

      } 
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.ownerOfPdvs.length
    }
  },
  methods: {
    Rech () {
      if (this.phoneNumber !== '') {
        this.$vs.loading()
        const phoneNumber = this.phoneNumber
        this.$http.post('owner-filter/', { phoneNumber })
          .then((response) => {
            this.ownerOfPdvs = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.notify({
              time:4000,
              title: 'ATTENTION',
              text: 'Entrez un numéro de téléphone.',
              color: 'warning',
              position:'top-center'
            })
            this.$vs.loading.close()
          })
      } else {
        this.$vs.notify({
          time:4000,
          title: 'ATTENTION',
          text: 'Entrez un numéro de téléphone.',
          color: 'warning',
          position:'top-center'
        })
        this.$vs.loading.close()
      }

    },
    async fetchData () {
      const response = this.ownerOfPdvs
      return response
    },
    addNewData () {
      this.intialise()
      this.activePrompt2 = true
    },
    intialise () {
      this.fullName = ''
      this.id = null
      this.fullName1 = ''
      this.fullName2 = ''
      this.fullName3 = ''
      this.fullName4 = ''
      this.fullName5 = ''

      this.region = ''
      this.gender = ''
      this.phoneNumber = ''
      this.birthdate = ''

      this.birthdate1 = ''
      this.birthdate2 = ''
      this.birthdate3 = ''
      this.birthdate4 = ''
      this.birthdate5 = ''

      this.address = ''
      this.corporate = ''
      this.maritalStatus = ''
      this.locality = ''
      this.particularCaseFiles = ''
      this.memberPicture = ''
      this.email = ''
      this.recipient1 = null
      this.recipient2 = null
      this.recipient3 = null
      this.recipient4 = null
      this.recipient5 = null
      this.name_file1_1 = ''
      this.name_file1_2 = ''
      this.name_file1_3 = ''
      this.name_file2_1 = ''
      this.name_file2_2 = ''
      this.name_file2_3 = ''
      this.name_file3_1 = ''
      this.name_file3_2 = ''
      this.name_file3_2 = ''
      this.name_file4_1 = ''
      this.name_file4_2 = ''
      this.name_file4_3 = ''
      this.name_file5_1 = ''
      this.name_file5_2 = ''
      this.name_file5_3 = ''
      this.name_file6 = ''
      this.dataImg1_1 = ''
      this.dataImg1_2 = ''
      this.dataImg1_3 = ''

      this.dataImg2_1 = ''
      this.dataImg2_2 = ''
      this.dataImg2_3 = ''

      this.dataImg3_1 = ''
      this.dataImg3_2 = ''
      this.dataImg3_3 = ''

      this.dataImg4_1 = ''
      this.dataImg4_2 = ''
      this.dataImg4_3 = ''


      this.dataImg5_1 = ''
      this.dataImg5_2 = ''
      this.dataImg5_3 = ''
      this.dataImg6 = ''

      this.date1 = 0
      this.date2 = 0
      this.date3 = 0
      this.date4 = 0
      this.date5 = 0
    },
  
    updateCurrImg1_1 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg1_1 = input.target.files
        this.name_file1_1 = input.target.files[0].name
        
      }
    },
    updateCurrImg1_2 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg1_2 = input.target.files
        this.name_file1_2 = input.target.files[0].name
        
      }
    },
    updateCurrImg1_3 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg1_3 = input.target.files
        this.name_file1_3 = input.target.files[0].name
        
      }
    },
    updateCurrImg2_1 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg2_1 = input.target.files
        this.name_file2_1 = input.target.files[0].name
        
  
      }
    },
    updateCurrImg2_2 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg2_2 = input.target.files
        this.name_file2_2 = input.target.files[0].name
        
  
      }
    },
    updateCurrImg2_3 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg2_3 = input.target.files
        this.name_file2_3 = input.target.files[0].name
        
  
      }
    },
    updateCurrImg3_1 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg3_1 = input.target.files
        this.name_file3_1 = input.target.files[0].name
      }
          
  
    },
    updateCurrImg3_2 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg3_2 = input.target.files
        this.name_file3_2 = input.target.files[0].name
      }    
    },
    updateCurrImg3_3 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg3_3 = input.target.files
        this.name_file3_3 = input.target.files[0].name
      }    
    },
    updateCurrImg4_1 (input) {
      if (input.target.files && input.target.files[0]) {
        
        this.dataImg4_1 = input.target.files
        this.name_file4_1 = input.target.files[0].name
          
      }
    },
    updateCurrImg4_2 (input) {
      if (input.target.files && input.target.files[0]) {
        
        this.dataImg4_2 = input.target.files
        this.name_file4_2 = input.target.files[0].name
          
      }
    },
    updateCurrImg4_3 (input) {
      if (input.target.files && input.target.files[0]) {
        
        this.dataImg4_3 = input.target.files
        this.name_file4_3 = input.target.files[0].name
          
      }
    },
    updateCurrImg5_1 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg5_1 = input.target.files
        this.name_file5_1 = input.target.files[0].name
          
      }
    },
    updateCurrImg5_2 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg5_2 = input.target.files
        this.name_file5_2 = input.target.files[0].name
      }
    },
    updateCurrImg5_3 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg5_3 = input.target.files
        this.name_file5_3 = input.target.files[0].name
      }
    },
    updateCurrImg6 (input) {
      if (input.target.files && input.target.files[0]) {
        this.dataImg6 = input.target.files
        this.name_file6 = input.target.files[0].name
      }
    },
    valider () {
      // if (!this.validateForm || !this.checkLogin()) return
      // eslint-disable-next-line no-unused-vars
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading()
          const formdata = new FormData()
          if (this.name_file1_1) {
            formdata.append('recipient1file1', this.dataImg1_1[0], this.name_file1_1)
          }
          if (this.name_file1_2) {
            formdata.append('recipient1file2', this.dataImg1_2[0], this.name_file1_2)
          }
          if (this.name_file1_3) {
            formdata.append('recipient1file3', this.dataImg1_3[0], this.name_file1_3)
          }
          if (this.name_file2_1) {
            formdata.append('recipient2file1', this.dataImg2_1[0], this.name_file2_1)
          }
          if (this.name_file2_2) {
            formdata.append('recipient2file2', this.dataImg2_2[0], this.name_file2_2)
          }
          if (this.name_file2_3) {
            formdata.append('recipient2file3', this.dataImg2_3[0], this.name_file2_3)
          }
          if (this.name_file3_1) {
            formdata.append('recipient3file1', this.dataImg3_1[0], this.name_file3_1)
          }
          if (this.name_file3_2) {
            formdata.append('recipient3file2', this.dataImg3_2[0], this.name_file3_2)
          }
          if (this.name_file3_3) {
            formdata.append('recipient3file2', this.dataImg3_3[0], this.name_file3_3)
          }
          if (this.name_file4_1) {
            formdata.append('recipient4file1', this.dataImg4_1[0], this.name_file4_1)
          }
          if (this.name_file4_2) {
            formdata.append('recipient4file2', this.dataImg4_2[0], this.name_file4_2)
          } 
          if (this.name_file4_3) {
            formdata.append('recipient4file2', this.dataImg4_3[0], this.name_file4_3)
          }
          if (this.name_file5_1) {
            formdata.append('recipient5file1', this.dataImg5_1[0], this.name_file5_1)
          }
          if (this.name_file5_2) {
            formdata.append('recipient5file2', this.dataImg5_2[0], this.name_file5_2)
          }
          if (this.name_file5_3) {
            formdata.append('recipient5file3', this.dataImg5_3[0], this.name_file5_3)
          }
          if (this.name_file6) {
            formdata.append('memberPicture', this.dataImg6[0], this.name_file6)
          }
          
          formdata.append('fullName', this.fullName)
          formdata.append('region', this.region)
          formdata.append('gender', this.gender)
          formdata.append('address', this.address)
          formdata.append('corporate', this.corporate)
          formdata.append('maritalStatus', this.maritalStatus)
          formdata.append('locality', this.locality)
          formdata.append('email', this.email)
          formdata.append('phoneNumber', this.phoneNumber)
          formdata.append('birthdate', this.birthdate) 

          if (this.recipient1) {
            formdata.append('recipient1', this.recipient1) 
            if (this.birthdate1) {
              formdata.append('birthdate1', this.birthdate1)
            }
            if (this.fullName1) {
              formdata.append('fullName1', this.fullName1)
            }

          } 
          if (this.recipient2) {
            formdata.append('recipient2', this.recipient2)
            if (this.birthdate2) {
              formdata.append('birthdate2', this.birthdate2)
            }
            if (this.fullName2) {
              formdata.append('fullName2', this.fullName2)
            }

          } 
          if (this.recipient3) {
            formdata.append('recipient3', this.recipient3) 
            if (this.birthdate3) {
              formdata.append('birthdate3', this.birthdate3) 
            }
            if (this.fullName3) {
              formdata.append('fullName3', this.fullName3)
            }

          } if (this.recipient4) {
            formdata.append('recipient4', this.recipient4) 
            if (this.birthdate4) {
              formdata.append('birthdate4', this.birthdate4) 
            }
            if (this.fullName4) {
              formdata.append('fullName4', this.fullName4)
            }

          } if (this.recipient5) {
            formdata.append('recipient5', this.recipient5) 
            if (this.birthdate5) {
              formdata.append('birthdate5', this.birthdate5) 

            }
            if (this.fullName5) {
              formdata.append('fullName5', this.fullName5)
            }

          } 
          let url = 'ownerOfPdvs/'
          let methods = 'post'
          if (this.id) {
            url += `${this.id}/`
            methods = 'put'
          }
          this.$http[methods](url, formdata)
          //this.$http.post('ownerOfPdvs/', formdata)
            .then((response) => {
              if (this.id) {
                this.$vs.loading.close()
                this.$vs.notify({
                  title: 'MISE À JOUR',
                  text: 'PVD modifié avec succès.',
                  color: 'success',
                  position: 'top-center'
                })
                this.activePrompt1 = false
              } else {
                this.$vs.notify({
                  title: 'INSCRIPTION',
                  text: 'Inscription avec succès.',
                  color: 'success',
                  position: 'top-center'
                })
              }
              if (this.id) {
                this.$vs.loading.close()
                this.updatecreate = true
                //this.checkId.push(response.data.id)
                this.UPDATE_TASK(response.data)
              } else {
                this.updatecreate = true
                //this.checkId.push(response.data.id)
                this.ownerOfPdvs.unshift(response.data)
                this.$vs.loading.close()
        
              }
              this.intialise()
              this.$validator.reset()
              this.$vs.loading.close()
            })
            .catch((error) => {
              const clefs = Object.keys(error.response.data)
              for (let i = 0; i < clefs.length; i++) {
                const item = clefs[i]
  
                if (item === 'fullName') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ Nom & prénoms ',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'gender') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ genre',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'phoneNumber') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Numéro de téléphone existant',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'birthdate') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ date de naissance',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'address') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ adresse',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'first_name') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ Prénoms vide ',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'corporate') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ entreprise',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'maritalStatus') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ situation matrimoniale',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'locality') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ localité',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'particularCaseFiles') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Fichier cas particulier',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'recipient2files') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Fichier benéficiare 2',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'recipient1files') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Fichier benéficiare 1',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'recipient3files') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Fichier benéficiare 3',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'recipient4files') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Fichier benéficiare 3',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'memberPicture') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ localité',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'email') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ email',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'locality') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Photo adhérant',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'email') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ email',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'recipient1') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ bénéficiare 1',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'recipient2') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ bénéficiare 2',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'recipient3') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ bénéficiare 3',
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'recipient4') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: 'Champ bénéficiare 4',
                    color: 'warning',
                    position: 'top-center'
                  })
                }
              }
              this.$vs.notify({
                time: 8000,
                title: 'ENREGISTREMENT',
                text: 'L\'enregistrement a échoué',
                color: 'danger',
                position: 'top-right'
              })
              this.$vs.loading.close()
            })
            
        }
          
      })
    },
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`ownerOfPdvs/${id}/`)
            .then(response => {
              //this.getAllownerOfPdvs()
              this.REMOVE_ITEM_PRODUIT(id)
              window.getPrendTaCom.success(this.$t('SuppressionPVD'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('Suppression')})
            })
        }
      })
  
    },
    REMOVE_ITEM_PRODUIT (produitId) {
      this.$vs.loading.close()
      const ItemIndex = this.ownerOfPdvs.findIndex((p) => p.id === produitId)
      this.ownerOfPdvs.splice(ItemIndex, 1)
    },
    UPDATE_TASK (task) {
      this.$vs.loading.close()
      const taskIndex =  this.ownerOfPdvs.findIndex((t) => t.id === task.id)
      Object.assign(this.ownerOfPdvs[taskIndex], task)
    },
    VoirData (data) {
      
      this.activePrompt3 = true
    
      this.bene_object = [
        {
          'nom': '',
          'birthday':'', 
          'situation':'',
          'fichiers':[] 
        },
        {
          'nom': '',
          'birthday':'', 
          'situation':'',
          'fichiers':[] 
        },
        {
          'nom': '',
          'birthday':'', 
          'situation':'',
          'fichiers':[] 
        },
        {
          'nom': '',
          'birthday':'', 
          'situation':'',
          'fichiers':[] 
        }
      ]
      this.bene_object[0].situation = (data.recipient1)

      this.bene_object[1].situation = (data.recipient2)

      this.bene_object[2].situation = (data.recipient3)

      this.bene_object[3].situation = (data.recipient4)

      this.bene_object[0].nom = (data.fullName1)
      this.bene_object[1].nom = (data.fullName2)
      this.bene_object[2].nom = (data.fullName3)
      this.bene_object[3].nom = (data.fullName4)

      this.bene_object[0].birthday = (data.birthdate1)
      this.bene_object[1].birthday = (data.birthdate2)
      this.bene_object[2].birthday = (data.birthdate3)
      this.bene_object[3].birthday = (data.birthdate4)

      if (data.recipient1file1) {
        if (this.updatecreate === true) {
          this.bene_object[0].fichiers.push((data.recipient1file1))
        } else {
          this.bene_object[0].fichiers.push((data.recipient1file1))
        }
      }
      if (data.recipient1file2) {
        if (this.updatecreate === true) {
          this.bene_object[0].fichiers.push((data.recipient1file2))
        } else {
          this.bene_object[0].fichiers.push((data.recipient1file2))

        }
      }
      if (data.recipient1file3) {
        if (this.updatecreate === true) {
          this.bene_object[0].fichiers.push((data.recipient1file3))
        } else {
          this.bene_object[0].fichiers.push((data.recipient1file3))
        }
      }
      if (data.recipient2file1) {
        if (this.updatecreate === true) {
          this.bene_object[1].fichiers.push((data.recipient2file1))

        } else {
          this.bene_object[1].fichiers.push((data.recipient2file1))
        }
      }
      if (data.recipient2file3) {
        if (this.updatecreate === true) {
          this.bene_object[1].fichiers.push((data.recipient2file2))
        } else {
          this.bene_object[1].fichiers.push((data.recipient2file2))
        }
      }
      if (data.recipient2file3) {
        if (this.updatecreate === true) {
          this.bene_object[1].fichiers.push((data.recipient2file3))
        } else {
          this.bene_object[1].fichiers.push((data.recipient2file3))
        }
      }
      if (data.recipient3file1) {
        if (this.updatecreate === true) {
          this.bene_object[2].fichiers.push((data.recipient3file1))
        } else {
          this.bene_object[2].fichiers.push((data.recipient3file1))
        }
      }
      if (data.recipient3file2) {
        if (this.updatecreate === true) {
          this.bene_object[2].fichiers.push((data.recipient3file2))
        } else {
          this.bene_object[2].fichiers.push((data.recipient3file2))
        }
      }
      if (data.recipient3file3) { 
        if (this.updatecreate === true) {
          this.bene_object[2].fichiers.push((data.recipient3file3))
        } else {
          this.bene_object[2].fichiers.push((data.recipient3file3))
        }
      }
      if (data.recipient4file1) {
        if (this.updatecreate === true) {
          this.bene_object[3].fichiers.push((data.recipient4file1))
        } else {
          this.bene_object[3].fichiers.push((data.recipient4file1))
        }
      }
      if (data.recipient4file2) {
        if (this.updatecreate === true) {
          this.bene_object[3].fichiers.push((data.recipient4file2))
        } else {
          this.bene_object[3].fichiers.push((data.recipient4file2))
        }
      }
      if (data.recipient4file3) {
        if (this.updatecreate === true) {
          this.bene_object[3].fichiers.push((data.recipient4file3))
        } else {
          this.bene_object[3].fichiers.push((data.recipient4file3))
        }
      }

    },

    openfiles (file) {
      if (this.updatecreate === true) {
        window.open(`${ link2  }${file}`, '_blank')
      } else if (this.updatecreate === false) {
        window.open(`${ link  }${file}`, '_blank')
      }
    },
    Voirpicture (data) {
      if (this.updatecreate === true) {
        window.open(`${ link2  }${(data.memberPicture)}`, '_blank')

      } else if (this.updatecreate === false) {
        window.open(`${ link  }${(data.memberPicture)}`, '_blank')
      }
    },
    editData (data) {
      this.activePrompt1 = true
      //this.input = JSON.parse(JSON.stringify(data))
      this.id = data.id
      this.fullName = data.fullName
      this.region = data.region.id
      this.gender = data.gender
      this.phoneNumber = data.phoneNumber
      this.birthdate = data.birthdate
      //this.memberPicture = data.memberPicture
      this.email = data.email
      this.address = data.address
      this.corporate = data.corporate
      this.maritalStatus = data.maritalStatus
      this.locality = data.locality

      this.fullName1 = data.fullName1
      this.fullName2 = data.fullName2
      this.fullName3 = data.fullName3
      this.fullName4 = data.fullName4

      this.birthdate1 = data.birthdate1
      this.birthdate2 = data.birthdate2
      this.birthdate3 = data.birthdate3
      this.birthdate4 = data.birthdate4

      this.recipient1 = data.recipient1
      this.recipient2 = data.recipient2
      this.recipient3 = data.recipient3
      this.recipient4 = data.recipient4


    },
   
    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    },
    getAllAgents () {
      this.$http.get('agents/')
        .then((response) => {
          this.agents = response.data
        })
        .catch(() => {
        })
    },
    getAllRegions () {
      this.$http.get('regions/')
        .then((response) => {
          this.regions = response.data
        })
        .catch(() => {
        })
    },
    getAllownerOfPdvs () {
      this.$vs.loading()
      //this.$http.get('ownerOfPdvs/')
      this.$http.post('owner-filter/', { region:1})
        .then((response) => {
          this.ownerOfPdvs = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllownerOfPdvs1 () {
      this.$vs.loading()
      this.$http.get('ownerOfPdvs/')
        .then((response) => {
          this.ownerOfPdvs = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  created () {
    window.getProspect = this
    this.user_role = JSON.parse(localStorage.getItem('userInfo')).role
    this.updatecreate = false
    this.getAllRegions()
    if (this.user_role !== 'agent') {
      this.getAllAgents()
    }
    if (this.user_role === 'agent') {
      this.getAllownerOfPdvs1()    
    } else {
      this.getAllownerOfPdvs()
    }
   

  },
  mounted () {
    this.isMounted = true
  }
}
</script>
  
  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
  
      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;
  
          .vs-table--search-input {
            width: 100%;
          }
        }
      }
  
      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }
  
      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;
  
          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }
  
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
            box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
            td{
              padding: 20px;
              &:first-child{
                border-top-left-radius: .5rem;
                border-bottom-left-radius: .5rem;
              }
              &:last-child{
                border-top-right-radius: .5rem;
                border-bottom-right-radius: .5rem;
              }
            }
            td.td-check{
              padding: 20px !important;
            }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
  